import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Vue3Lottie from 'vue3-lottie'
import store from './store'
import { Buffer } from 'buffer'
import titleMixin from './plugins/titleMixin'
global.Buffer = Buffer

createApp(App)
  .mixin(titleMixin)
  .use(Vue3Lottie)
  .use(store)
  .use(router)
  .use(vuetify)
  .mount('#app')

import * as VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundPage from '../views/NotFoundPage'
const isServer = typeof window === 'undefined'
const history = isServer ? VueRouter.createMemoryHistory() : VueRouter.createWebHistory()

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/sanctum',
    name: 'sanctum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SanctumView.vue')
  },
  {
    path: '/pilgrims',
    name: 'pilgrims',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PilgrimsView.vue')
  },
  {
    path: '/pilgrims/:url',
    name: 'SinglePilgrim',
    props: true,

    component: () => import(/* webpackChunkName: "about" */ '../views/SinglePilgrimPage.vue')
  },
  {
    path: '/:pathMatch(.*)*', redirect: '/404'
  },
  {
    path: '/404', component: NotFoundPage
  }
]

const router = VueRouter.createRouter({
  history,
  routes
})

export default router

<template>
  <v-divider color="white"></v-divider>
  <v-container class="footer">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="d-flex align-center"
      >
        <img :src="require('@/assets/hp-logo.png')" alt="hp-logo" class="footer-logo">
        <div class="footer-text">
          Embark on the Mystical: Unravel, Unite, and Transcend with Holistic Pilgrim.
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        lg="3"
      >
        <div class="footer-text" style="padding-left:0; text-align: center">
          Follow Us
        </div>
        <div class="d-flex align-center justify-center footer-social">
          <a href="https://twitter.com/NEARHolystic" target="_blank"><img :src="require('@/assets/twitter-logo.png')" alt=""></a>
          <a href="https://discord.gg/7hFmZ8Ph2B" target="_blank"><img :src="require('@/assets/discord-icon.png')" alt=""></a>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="5"
        lg="5"
      >
        <span class="footer-copyright">Copyright © 2023</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FooterComp'
}
</script>

<style scoped>

</style>

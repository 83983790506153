<template>
  <main>
    <NavPage/>
    <transition name="fade" mode="out-in">
    <router-view/>
    </transition>
    <FooterComp/>
  </main>
</template>

<script>
import NavPage from '@/components/NavPage.vue'
import FooterComp from '@/components/FooterComp.vue'
import { defineComponent } from 'vue'

export default defineComponent({

  name: 'App',
  computed: {
  },
  data () {
  },
  methods: {

  },
  components: {
    NavPage,
    FooterComp
  }
})

</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@font-face {
  font-family: "Pixel Operator";
  src: local("Pixel Operator"),
  url(./assets/fonts/PixelOperator.ttf) format("truetype");
}
@font-face {
  font-family: "Pixel Operator 8";
  src: local("Pixel Operator 8"),
  url(./assets/fonts/PixelOperator8.ttf) format("truetype");
}

@font-face {
  font-family: "Pixel Operator HB";
  src: local("Pixel Operator HB"),
  url(./assets/fonts/PixelOperatorHB.ttf) format("truetype");
}
@font-face {
  font-family: "Pixel Operator SC";
  src: local("Pixel Operator SC"),
  url(./assets/fonts/PixelOperatorSC.ttf) format("truetype");
}

@font-face {
  font-family: "Pixel Operator Mono";
  src: local("Pixel Operator Mono"),
  url(./assets/fonts/PixelOperatorMono.ttf) format("truetype");
}

@font-face {
  font-family: "Pixel Operator Mono HB";
  src: local("Pixel Operator Mono HB"),
  url(./assets/fonts/PixelOperatorMonoHB.ttf) format("truetype");
}

@font-face {
  font-family: "Pixel Operator HB SC";
  src: local("Pixel Operator HB SC"),
  url(./assets/fonts/PixelOperatorHBSC.ttf) format("truetype");
}
@font-face {
  font-family: "Pixel Operator HB";
  src: local("Pixel Operator HB"),
  url(./assets/fonts/PixelOperatorHB.ttf) format("truetype");
}
@font-face {
  font-family: "ixel Operator Mono HB";
  src: local("ixel Operator Mono HB"),
  url(./assets/fonts/PixelOperatorMonoHB.ttf) format("truetype");
}
@font-face {
  font-family: "Alagard";
  src: local("Alagard"),
  url(./assets/fonts/alagard.ttf) format("truetype");
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '@/styles.less'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify
import { createVuetify } from 'vuetify'

const hpDark = {
  dark: true,
  colors: {
    body: '#141218',
    background: '#141218',
    surface: '#141218',
    primary: '#ffffff',
    font: '#ffffff',
    grey: '#888888',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00'
  }
}

const hpLight = {

  colors: {
    background: '#ffffff',
    body: '#ffffff',
    surface: '#ffffff',
    primary: '#000000',
    font: '#000000'
  }
}

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'hpDark',

    themes: {
      hpDark,
      hpLight
    }
  }
})

<template>

  <div class="home d-flex flex-column ">
    <v-spacer></v-spacer>
    <div class="middle-text">
      <h1>
        Holistic Pilgrim
      </h1>
      <h2 class="aeon-of-metamystics">Aeon of Metamystics</h2>
    </div>
    <v-spacer></v-spacer>
    <div class="bottom-text">
      <p>
        Within the realm of Holistic Pilgrim, threads of fate entwine, creating an eternal tapestry where memories,
        magic, and mysteries converge.
      </p>
    </div>
  </div>
  <div class="home-relics">
    <div class="h1-deg">
      Holistic Relics
    </div>
    <v-container>
      <div class="home-pilgrims-text">
        In the mystical realm of Holistic Pilgrim, Holistic Relics serve as a bridge connecting travelers from myriad dimensions. These extraordinary wanderers, each a singular embodiment of an enigmatic past, present, and future, are bound by the cosmic threads of fate. As they traverse the interconnected worlds with their unique abilities and hidden secrets, their tales weave an intricate narrative of discovery, wonder, and adventure. Like echoes in the fabric of time, these relics reveal the profound connections that bind us all, forming the tapestry of the ever-expanding Holistic Pilgrim metaverse.
      </div>
      <div class="d-flex home-pilgrims">
        <img :src="data[0].pilgrim_1" alt="">
        <img :src="data[0].pilgrim_2" alt="">
        <img :src="data[0].pilgrim_3" alt="">
        <img :src="data[0].pilgrim_4" alt="">
        <img :src="data[0].pilgrim_5" alt="">
        <img :src="data[0].pilgrim_6" alt="">
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  title: 'Holistic Pilgrim',
  name: 'HomeView',
  data () {
    return {
      data: [
        {
          pilgrim_1: require('@/assets/pilgrim-1.png'),
          pilgrim_2: require('@/assets/pilgrim-2.png'),
          pilgrim_3: require('@/assets/pilgrim-3.png'),
          pilgrim_4: require('@/assets/pilgrim-4.png'),
          pilgrim_5: require('@/assets/pilgrim-5.png'),
          pilgrim_6: require('@/assets/pilgrim-6.png')
        }
      ]
    }
  },
  components: {
  }
}
</script>

<style>
html,
body {
  margin: 0;
}

.home {
}

.home {

}

</style>

<template>
  <v-card flat>
    <div class="text-center">
      The journey stops here, for there is no key to unlock this realm of shadows. But never despair for the answer could still be out there, and it is never too late to open the door.
    </div>

    <div class="text-center">
      <router-link to="/">Go Home</router-link>
      <router-view/>
    </div>

  </v-card>
</template>

<script>

export default {
  name: 'NotFoundPage',
  title: '404',
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
